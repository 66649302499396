import React from "react";
import { BsLinkedin, BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a href="https://www.linkedin.com/in/adrian-milewski-6ba4a120a" target="_blank" rel="noreferrer">
          <BsLinkedin />
      </a>
      <a href="https://www.facebook.com/adrian.milewski.562/" target="_blank" rel="noreferrer">
          <FaFacebookF />
      </a>
      <a href="https://www.instagram.com/yetibrother/" target="_blank" rel="noreferrer">
          <BsInstagram />
      </a>
    </div>
  );
};

export default SocialMedia;
